<template>
  <v-card>
    <v-card-title>Reset Password</v-card-title>
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-text-field v-model="email" :rules="[v => !!v || 'Email is required']" name="email" label="E-mail" prepend-icon="email"/>
          </v-col>
        </v-row>
        <v-row v-if="!submitted">
          <v-col cols="8">
            <v-btn :disabled="!valid" color="primary" class="mr-4" @click="sendReset">
              Send Password Reset Email
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="errored">
          <v-col cols="8">
            <v-alert type="error" dismissible>
              {{errored}}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="success">
          <v-col cols="8">
            <v-alert type="success" dismissible>
              {{success}}
              <router-link to="signin">Click here</router-link> to sign in.
            </v-alert>
          </v-col>
        </v-row>
        <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                color="teal accent-4"
        ></v-progress-linear>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
  export default {
    name: 'pass-reset',
    data: () => ({
      email: null,
      errored: null,
      success: null,
      valid: false,
      submitted: false,
      loading: false
    }),
    methods: {
      sendReset () {
        if (this.email) {
          this.submitted = true
          this.loading = true
          this.$firebase.auth().sendPasswordResetEmail(this.email).then(() => {
            this.success = `Email has been sent to ${this.email}`
            this.loading = false
          }).catch(err => {
            this.loading = false
            this.errored = err.message
            this.submitted = false
          })
        }
      }
    }
  }
</script>
